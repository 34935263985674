<template>
    <div class="grid">
        <div class="col-12">
            <div class="font-medium text-xl text-900 mb-3">
                <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                    <AppInfoManual nomeTelaDoManual="riscos-exames-form" />
                    <h3 class="ml-1">{{ title }}</h3>
                </div>
            </div>
            <div class="text-500 mb-5">Preencha os campos abaixo</div>
            <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
            <div :style="loading ? 'opacity: 30%;' : ''">
                <div class="card p-fluid w-full">
                    <h5 class="mb-4">Novo risco</h5>

                    <Toast />

                    <div class="">
                        <div class="field">
                            <label for="risk_id">Risco</label>
                            <Dropdown
                                id="risk_id"
                                v-model="form.riskId"
                                :options="risks"
                                optionLabel="agentName"
                                optionValue="id"
                                placeholder="Escolha"
                            ></Dropdown>
                        </div>

                        <div class="field">
                            <label for="procedure_id">Exame</label>
                            <Dropdown
                                id="procedure_id"
                                v-model="form.procedureId"
                                :options="procedures"
                                optionLabel="name"
                                optionValue="id"
                                placeholder="Escolha"
                            ></Dropdown>
                        </div>
                    </div>

                    <div class="flex mt-5">
                        <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
                        <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto" @click="$router.push('/risk-procedure')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseService from '@/services/BaseService';
import { getCurrentCompany } from '@/services/store';
import { getClientBase } from '../../services/http';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    data() {
        return {
            form: {},
            risks: [],
            procedures: [],
            submitted: false,
            loading: false
        };
    },
    async mounted() {
        this.loading = true;
        this.$service = new BaseService('/risk/procedures');
        try {
            await this.loadRisks();
            await this.loadProcedures();
            if (this.$route.params.id) {
                const { data } = await this.$service.findById(this.$route.params.id);
                this.form = data;
            }
        }
        catch (error) {
            this.loading = false;
            this.$toast.add({
                severity: 'error',
                summary: 'Problemas ao carregar informações!',
                detail: error.message,
                life: 3000
            });
        }
        this.loading = false;
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar risco exame' : 'Adicionar risco exame';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    methods: {
        async save() {
            this.submitted = true;
            try {
                await this.$service.save(this.form);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Risco exame salvo com sucesso!',
                    life: 3000
                });
                this.$router.replace('list');
            }
            catch (err) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problemas ao salvar o risco exame!',
                    life: 3000
                });
            }
        },
        async loadRisks() {
            const { data } = await getClientBase().get('/risks/combo');
            this.risks = data;
        },
        async loadProcedures() {
            const { data } = await getClientBase().get('/procedures/combo');
            this.procedures = data;
        }
    },
    components: { AppInfoManual }
};
</script>

